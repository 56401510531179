import { useWeb3React } from '@web3-react/core'
import { Dropdown, Tooltip } from 'antd'
import useIsMobile from 'hooks/useIsMobile'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import styled from 'styled-components'
import { Link } from 'widgets'
import LocalizeSelector from 'components/LocalizeSelector'
import { useTranslation } from 'contexts/Localization'
import ConnectWalletButton from 'components/ConnectWalletButton'
import CollapseIcon from './CollapseIcon'
import MobileSlider from './MobileSlider'
import { MenuEntry } from './types'

const WrapperHeader = styled.header<{ background: string }>`
  position: fixed;
  display: flex;
  align-items: center;
  width: calc(100% - 80px);
  min-height: 75px;
  transition: 0.25s;
  z-index: 999;
  border-radius: 50px;
  margin: 32px 40px;
  background: ${({ background }) => background};

  box-shadow: ${({ background }) =>
    background === '#fff' ? `none` : `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`};

  &.active {
    display: none;
    background: transparent;
    box-shadow: none;

    .content {
      .menu-left {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .content {
    position: relative;
    padding: 10px 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 0 32px;
    }

    .menu-left {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 50px;
        min-width: 50px;

        ${({ theme }) => theme.mediaQueries.lg} {
          width: 89px;
          min-width: 89px;
        }
      }

      .menu-list {
        width: 100%;
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0 15px 0 35px;

        li {
          &:not(:last-child) {
            margin-right: 30px;
          }
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #000;
            display: block;

            &:hover {
              color: #006dfe;
            }
          cursor: pointer;
          

          &.active a {
            font-weight: 600;
            color: #006dfe;
          }
        }
      }
    }

    .menu-right {
      display: flex;
      align-items: center;

      .notify-icon {
        width: 30px;
        position: relative;
        margin-right: 16px;
        cursor: pointer;

        span {
          position: absolute;
          top: 2px;
          right: -3px;
          width: 18px;
          height: 18px;
          background: #f41706;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 100%;
        }
      }

      .menu-actions-desktop {
        position: relative;
        display: flex;
        align-items: center;

        button {
          &:not(:last-child) {
            margin-right: 13px;
          }
        }
      }

      .open-app-btn {
        width: 170px;
        margin-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        padding: 9px 14px;
        font-size: 14px;
      }
    }
  }
`
const MenuSubItem = styled.div`
  padding-top: 12px;
  ul {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    position: relative;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    list-style: none;
    font-size: 14px;
    &::before {
      content: '';

      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #eab962;
      z-index: 2;

      position: absolute;
      left: 24px;
      bottom: 100%;
    }
  }

  li {
    margin-right: 30px;

    a {
      color: #000;
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.04em;
      display: block;
      .icon-submenu-custom{
        filter: invert(7%) sepia(5%) saturate(7479%) hue-rotate(231deg) brightness(109%) contrast(102%);
        zoom: 1.4;
        margin-left: -3px;
      }
      &:hover {
        color: #006dfe;
        .icon-submenu{
          filter: invert(26%) sepia(52%) saturate(4406%) hue-rotate(207deg) brightness(102%) contrast(108%);
        }
        .icon-submenu-custom{
          filter: invert(26%) sepia(52%) saturate(4406%) hue-rotate(207deg) brightness(102%) contrast(108%);


        }
        
      }
    }

    &.active a {
      color: #fff;
    }
  }
  .header-connect-button{
    font-size: 14px;
  }
  .cursor-tooltip{
    cursor: pointer;
  }
  .icon-submenu{
    filter: invert(80%) sepia(0%) saturate(5574%) hue-rotate(183deg) brightness(97%) contrast(98%);
    padding-right: 8px;
    width: 30px;
  }
`

interface Props {
  menuItems: MenuEntry[]
  hasSider?: boolean
}

const mobileWith = 1024

const Header: React.FC<Props> = ({ menuItems, hasSider = false }) => {
  const router = useRouter()
  const [isScroll, updateIsScroll] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMobile = useIsMobile(mobileWith)
  const { account } = useWeb3React()

  const [showSlider, setShowSlider] = useState(false)

  const toggleWallet = () => {
    dispatch(setModalConnect({ toggle: true }))
  }

  const toggleMenu = () => {
    setShowSlider((prev) => !prev)
  }

  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : undefined
  const paths = router.pathname.split('/').map((path) => `/${path}`)

  const changeBackground = () => {
    if (window.scrollY >= 30) {
      updateIsScroll(true)
    } else {
      updateIsScroll(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
  })

  return (
    <>
      <WrapperHeader className={`${showSlider && 'active'}`} background={!isScroll ? '#fff' : '#fff'}>
        <div className="content">
          {!hasSider ? (
            <div className="menu-left">
              <Link href="/">
                {!isMobile ? <img src="/images/logo.svg" alt="" /> : <img src="/images/mb-logo.png" alt="" />}
              </Link>

              {!isMobile && (
                <ul className="menu-list">
                  {menuItems.map((entry: any) => {
                    const isActive = entry.actives.includes(paths[1])
                    if (entry.items) {
                      return (
                        <Dropdown
                          key={entry.label}
                          overlay={
                            <MenuSubItem>
                              <ul>
                                {entry.items.map((subMenu) => {
                                  const subMenuActive = subMenu.actives.includes(paths[1])
                                  return (
                                    <li key={subMenu.label} className={subMenuActive ? 'active' : ''}>
                                      <Link href={subMenu.href} rel="noopener noreferrer" target="_blank">
                                        <img className={`icon-submenu ${subMenu.label === 'Twitter' && 'icon-submenu-custom'}`} src={subMenu.icon} alt="" />
                                        {t(subMenu.label)}
                                      </Link>
                                    </li>
                                  )
                                })}
                              </ul>
                            </MenuSubItem>
                          }
                        >
                          <li key={entry.label} className={isActive ? 'active' : ''}>
                            {/* <Link href={entry.href} target={entry.target}> */}
                            <span>
                              {t(entry.label)}
                            </span>
                            {/* </Link> */}
                          </li>
                        </Dropdown>
                      )
                    }
                    return (
                      <li key={entry.label} className={`cursor-tooltip ${paths.includes(entry.href || '') ? 'active' : ''}`}>
                        {
                          entry.isComing ?
                            <Tooltip
                              placement="bottom"
                              color="#ffffff"
                              title={
                                <p className="tooltip-footer">
                                  {t('Coming Soon')}
                                </p>
                              }
                            >
                              {t(entry.label)}
                            </Tooltip>
                            :
                            <Link href={entry.href} rel="noopener noreferrer" target={entry?.isNoTarget ? '' : "_blank"}>
                              {t(entry.label)}
                            </Link>
                        }
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          ) : (
            <div />
          )}

          <div className="menu-right">
            {isMobile ? (
              <CollapseIcon active={showSlider} toggleMenu={toggleMenu} />
            ) : (
              <div className="menu-actions-desktop">
                <Tooltip
                  placement="bottom"
                  color="#ffffff"
                  title={
                    <p className="tooltip-footer">
                      Coming Soon
                    </p>
                  }
                >
                  <ConnectWalletButton style={{ fontSize: "14px", width: "145px" }} />

                </Tooltip>
                {/* <Box mx={9}/> */}
                {/* <CustomFlagsSelector /> */}

                <LocalizeSelector />
              </div>
            )}
          </div>
        </div>
      </WrapperHeader>
      {isMobile && (
        <MobileSlider
          showSlider={showSlider}
          visible={showSlider}
          links={menuItems}
          accountEllipsis={accountEllipsis}
          toggleWallet={toggleWallet}
          toggleMenu={toggleMenu}
        />
      )}
    </>
  )
}

export default Header
