/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip, Typography } from 'antd'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { Flex, Link } from 'widgets'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const FooterWrapper = styled.footer<{ txtCommingSoon: string }>`
  position: relative;
  z-index: 100;
  padding: 32px 0 28px 0;
  background: #05050f;
  color: #f5f6fa;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 3rem 0 160px 0;
  }

  ul {
    list-style-type: none;
    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;

        ${({ theme }) => theme.mediaQueries.sm} {
          margin-bottom: 15px;
        }
      }
    }
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #f5f6fa;
    margin-bottom: 30px;
  }

  .footer-content {
    justify-content: space-between;
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.md} {
      flex-direction: row;
    }
  }

  .b-left {
    margin-bottom: 29px;

    ${({ theme }) => theme.mediaQueries.lg} {
      margin-bottom: 2.5rem;
    }

    .logo {
      margin-bottom: 17px;
      justify-content: center;

      ${({ theme }) => theme.mediaQueries.sm} {
        margin-bottom: 15px;
      }

      img:first-child {
        margin-right: 4.21px;

        ${({ theme }) => theme.mediaQueries.sm} {
          margin-right: 20px;
        }
      }

      img {
        &:first-child {
          width: 38.39px;
        }

        &:nth-child(2) {
          width: 68.4px;
          margin-top: 9px;
        }

        ${({ theme }) => theme.mediaQueries.sm} {
          &:first-child {
            width: auto;
          }

          &:nth-child(2) {
            width: auto;
            margin-top: 0;
          }
        }
      }
    }

    p {
      text-align: center;
    }

    .description {
      p {
        margin-bottom: 10px;

        ${({ theme }) => theme.mediaQueries.lg} {
          margin-bottom: 10px;
        }
      }
    }

    ${({ theme }) => theme.mediaQueries.md} {
      margin-bottom: 0;

      .logo {
        justify-content: flex-start;
      }

      p {
        text-align: left;
      }
    }
  }

  .b-right {
    width: 100%;

    .ant-col {
      padding-top: 29px;
      padding-bottom: 5px;
      border-top: 0.2px solid #fff;
      max-width: calc(100% - 40px);
      margin: auto;

      ${({ theme }) => theme.mediaQueries.sm} {
        padding-top: 0;
        padding-bottom: 0;
        border-top: none;
        max-width: unset;
      }
    }

    h3 {
      text-align: center;
    }

    ul {
      li {
        text-align: center;
      }
    }
    ${({ theme }) => theme.mediaQueries.sm} {
      h3 {
        text-align: left;
      }

      ul {
        li {
          text-align: left;
        }
      }
    }

    ${({ theme }) => theme.mediaQueries.md} {
      width: 70%;
    }
  }

  .social-network {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: auto;

    ${({ theme }) => theme.mediaQueries.xs} {
      max-width: 60%;
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      max-width: 100%;
    }
  }

  .copyright {
    padding-top: 37px;
    text-align: center;
    color: #6D6D6D;
    font-size: 12px;
  }

  .comming-soon {
    position: relative;
    cursor: pointer;
    &:after {
      content: '${({ txtCommingSoon }) => txtCommingSoon}';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background-color: #fff;
      z-index: 50;
      color: black;
      padding: 5px 10px;
      border-radius: 10px;
      transition: all 0.35s ease-in-out;
      opacity: 0;
      padding-bottom: 7px;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }

  .social-network {
    margin-top: 51px;
    .comming-soon {
      &:after {
        left: 50%;
        right: auto;
        transform: translate(-50%, -50%);
      }
    }
  }
  .item-footer{
    width: 46px;
    height: 46px;
    border-radius: 50%;
    position: relative;
    background: #fff;
    text-align: center;
    &.twitter-new img{
      zoom: 1.4;
    }
    img{
      position: absolute;
      left: 50%;
      filter: invert(44%) sepia(4%) saturate(11%) hue-rotate(352deg) brightness(93%) contrast(81%);
      top: 50%;
      transform: translate(-50%, -50%);

    }
    &:hover{
      background: #006DFE;
      img{
        filter: unset;
        transition: all 0.2s ease-in-out;
      }
      &.twitter-new:hover{
        img{
          filter: invert(100%) sepia(19%) saturate(2%) hue-rotate(170deg) brightness(109%) contrast(100%);
        }
      }
    }
  }
  .about-us-box{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    color: #6D6D6D;
    font-size: 13px;
    gap: 20px;
    ${({ theme }) => theme.mediaQueries.sm} {
      gap: 30px;
    }
    div{
      &:last-child{
        margin-right: 0;

      }
    }
  }
  .more-info{
    font-size: 14px;
    font-family: Inter;
    line-height: 19px;

    &.is-hidden{
      visibility: hidden;
    }
    .fw-700{
      font-weight: 700;
    }
    .email{
      color: #2AF6FF;
      line-height: 20px;
      position: relative;
      top: -2px;
    }
  }
`


const Footer = () => {
  const { t } = useTranslation()
  const { Text } = Typography;
  const router = useRouter()
  const routerAsPath = router.asPath
  const [isHiddenInfo, setIsHiddenInfo] = useState(false)

  useEffect(() => {
    if (routerAsPath && routerAsPath.split('/')[1]) {
      const currentLink = routerAsPath.split('/')[1]
      if (currentLink === 'buy') {
        setIsHiddenInfo(true)
      } else {
        setIsHiddenInfo(false)
      }
    } else {
      setIsHiddenInfo(false)
    }
  }, [routerAsPath])

  return (
    <FooterWrapper txtCommingSoon={t('Sắp có')}>
      <div className='container'>
        <p className={`${isHiddenInfo && 'is-hidden'} more-info`}>
          {t("For more information, please contact us with the subject")}

          <span className='fw-700'> &quot;FAQ&quot; </span>{t("via")} email:&nbsp;
          <a href="mailto:mathew@asdi.foundation">
            <Text underline className='email'>mathew@asdi.foundation</Text>
          </a>
        </p>
        <Flex className="social-network">
          <Tooltip
            placement="bottom"
            color="#ffffff"
            title={
              <p className="tooltip-footer">
                Telegram
                <br /> Channel
              </p>
            }
          >
            <a className="tele-group item-footer" href="http://t.me/asdinft_ann" target="_blank" rel="noreferrer">
              <img src="/images/social-network/telegram.svg" alt="" />
            </a>
          </Tooltip>

          <Tooltip
            placement="bottom"
            color="#ffffff"
            title={
              <p className="tooltip-footer">
                Telegram <br /> Group
              </p>
            }
          >
            <a
              className="tele-channel item-footer"
              href="http://t.me/asdinft"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/social-network/telegram.svg" alt="" />
            </a>
          </Tooltip>

          <Tooltip placement="bottom" color="#ffffff" title={<p className="tooltip-footer">Twitter</p>}>
            <a className='item-footer twitter-new' href="https://twitter.com/asdinft" target="_blank" rel="noreferrer">
              <img width={23} height={23} src="/images/social-network/twitter.svg" alt="" />
            </a>
          </Tooltip>

          <Tooltip placement="bottom" color="#ffffff" title={<p className="tooltip-footer">LinkedIn</p>}>
            <a className='item-footer' href="https://www.linkedin.com/company/asdinft" target="_blank" rel="noreferrer">
              <img src="/images/social-network/linkedin.png" alt="" />
            </a>
          </Tooltip>

          <Tooltip placement="bottom" color="#ffffff" title={<p className="tooltip-footer">Facebook</p>}>
            <a className='item-footer' href="https://www.facebook.com/asdinft" target="_blank" rel="noreferrer">
              <img src="/images/social-network/facebook.png" alt="" />
            </a>
          </Tooltip>

          <Tooltip placement="bottom" color="#ffffff" title={<p className="tooltip-footer">Discord</p>}>
            <a className='item-footer' href="https://discord.com/invite/Cn6CKcUYYp" target="_blank" rel="noreferrer">
              <img src="/images/social-network/discord.png" alt="" />
            </a>
          </Tooltip>

          <Tooltip placement="bottom" color="#ffffff" title={<p className="tooltip-footer">Youtube</p>}>
            <a className='item-footer' href="https://www.youtube.com/@asdinft" target="_blank" rel="noreferrer">
              <img src="/images/social-network/youtube.png" alt="" />
            </a>
          </Tooltip>

          <Tooltip placement="bottom" color="#ffffff" title={<p className="tooltip-footer">Instagram</p>}>
            <a className='item-footer' href="https://www.instagram.com/asdinft" target="_blank" rel="noreferrer">
              <img src="/images/social-network/instagram.png" alt="" />
            </a>
          </Tooltip>

          <Tooltip placement="bottom" color="#ffffff" title={<p className="tooltip-footer">Medium</p>}>
            <a className='item-footer' href="https://medium.com/@asdinft" target="_blank" rel="noreferrer">
              <img src="/images/social-network/ss.png" alt="" />
            </a>
          </Tooltip>
        </Flex>

        <Flex className='about-us-box' justifyContent="center">
          <div>
            <Link href="https://asdi.foundation/about-us">{t('Về chúng tôi')}</Link>
          </div>
          <div>
            <Link href="https://asdi.foundation/opportunity">{t('Cơ hội nghề nghiệp - Footer')}</Link>
          </div>
          <div>
            <Link href="https://asdi.foundation/policy?o=general">{t('Điều khoản')}</Link>
          </div>
          <div>
            <Link href="https://asdi.foundation/policy?o=privacy">{t('Riêng tư')}</Link>
          </div>
          <div>
            <Link href="https://asdi.foundation/policy?o=risk">{t('Khuyến cáo rủi ro')}</Link>
          </div>
        </Flex>

      </div>

      <div className="copyright">
        <p>&copy; {t('2023 ASDI Foundation. All rights reserved.')}</p>
      </div>

    </FooterWrapper>
  )
}

export default Footer
