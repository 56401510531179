/* eslint-disable no-new */
/* eslint-disable dot-notation */
import '../style/animation.css'
import 'antd/dist/antd.css'
import { ResetCSS, Spinner } from '@pancakeswap/uikit'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import BigNumber from 'bignumber.js'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import FixedSubgraphHealthIndicator from 'components/SubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Fragment, useEffect, useRef, useState } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { useStore, persistor } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { NextPage } from 'next'
// import useGetBalance from 'hooks/useGetBalance'
import PublicLayout from 'layouts/PublicLayout'
import useGetBalance from 'hooks/useGetBalance'
import { TokenUpdater } from 'state/info/updaters'
import { useRouter } from 'next/router'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Blocklist, Updaters } from '..'
import ErrorBoundary from '../components/ErrorBoundary'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  return null
}

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noodp,noydir" />

        {/* <!-- Link --> */}
        <link rel="apple-touch-icon" type="image/x-icon" href="%PUBLIC_URL%/favicon.ico" />
        <link rel="shortcut icon" type="image/png" href="%PUBLIC_URL%/favicon.ico" />

        {/* <!-- Og --> */}
        <meta property="og:title" content="ASDI - Asset Digitization NFT Platform " />
        <meta
          property="og:description"
          content="Building a web3 platform, where every user has the right to self-determination with their own assets in an open and transparent manner."
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="ASDI - Asset Digitization NFT Platform " />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="ASDI" />
        <meta property="og:image" content="https://asdinft.info/graph-en.png" />
        <meta property="og:url" content="https://asdinft.info" />

        {/* <!-- Twitter --> */}
        <meta name="twitter:title" content="ASDI - Asset Digitization NFT Platform " />
        <meta
          name="twitter:description"
          content="Building a web3 platform, where every user has the right to self-determination with their own assets in an open and transparent manner."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ASDI" />
        <meta name="twitter:image" content="https://asdinft.com/asdinft.info" />
        <meta name="twitter:creator" content="@ASDI" />

        {/* <!-- Title --> */}
        <title>ASDI TOKEN - Asset Digitization NFT Platform</title>
        <meta name="keywords" content="ASDI,NFT,Marketplace" />
        <meta
          name="description"
          content="Building a web3 platform, where every user has the right to self-determination with their own assets in an open and transparent manner."
        />
      </Head>
      <Providers store={store}>
        <Blocklist>
          <GlobalHooks />
          <Updaters />
          <ResetCSS />
          <GlobalStyle />
          <GlobalCheckClaimStatus excludeLocations={[]} />
          <PersistGate loading={null} persistor={persistor}>
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-TLVDDR8GBD" />
      <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-TLVDDR8GBD');        
          `,
        }}
      />
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
  fullLayout?: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps, ...props }: AppPropsWithLayout) => {
  const [loading, setLoading] = useState(true)
  const router = useRouter()
  const loadingSet = useRef(false);

  useGetBalance()
  // Use the layout defined at the page level, if available
  const Layout = Fragment

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      if (loadingSet.current === false) {
        setLoading(true);
        loadingSet.current = true;
      }
    })
    if (loading) {
      if (['home', 'news', 'support'].every((x) => x !== router?.pathname)) {
        setTimeout(() => setLoading(false), 1000)
      }
    }
  }, [router])

  return (
    <ProductionErrorBoundary>

      <TokenUpdater />
      <ParallaxProvider>

        <Layout>
          <div id="google_translate_element" />
          {Component.fullLayout ? (
            <Component {...pageProps} />
          ) : (
            <PublicLayout>
              <Spinner isLoading={loading} />
              <Component {...pageProps} setLoading={setLoading} />
            </PublicLayout>
          )}
        </Layout>
      </ParallaxProvider>

      <EasterEgg iterations={2} />
      <ToastListener />
      <FixedSubgraphHealthIndicator />

    </ProductionErrorBoundary>
  )
}

export default MyApp
