import styled from 'styled-components'
import BaseButton from 'widgets/BaseButton'
import { useRouter } from 'next/router'
import LocalizeSelector from 'components/LocalizeSelector'
import { useTranslation } from 'contexts/Localization'
import { Box } from 'widgets'
import useToast from 'hooks/useToast'
import Accordion from './Accordion'
import { MenuEntry, MenuLink, MenuSubEntry } from './MenuEntry'
import { MenuEntry as MenuEntryTypes } from './types'
import CollapseIcon from './CollapseIcon'
import SubListItem from './SubListItem'

const Wrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 20px 2rem;
  z-index: 900;
  overflow: auto;
  transition: 0.25s;
  transform: ${({ visible }) => (visible ? 'translateX(0)' : 'translateX(100%)')};
  background: #006dfe;

  .no-link{
    pointer-events: none;
    width: fit-content;
    padding: 8px 0;
    *{
      width: fit-content;

    }
  }

  ul {
    #selected-country-btn {
      border: 0.7px solid #efefef;

      > span:after {
        color: #efefef;
      }

      &:hover,
      &:focus {
        border: 0.7px solid #efefef;
      }
    }

    .country-selector button:after {
      background-color: #fff;
      -webkit-mask: url('/images/icons/arrow-right-02.svg');
      mask-image: url('/images/icons/arrow-right-02.svg');
    }
  }

  .social-network {
    display: flex;
    align-item: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 10px;
    min-height: 67px;
    padding: 20px 15px;
    border: 1px solid #2af6ff;
    border-radius: 30px;
    width: 100%;
    margin-top: 32px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
      padding: 0 8px;
      text-align: center;

      ${({ theme }) => theme.mediaQueries.sm} {
        width: 11.1111111111%;
      }

      img {
        width: 100%;
        max-width: 42px;
        opacity: 0.8;
        transition: 0.25s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
`

const MenuActionsMobile = styled.div`
  width: fit-content;
  margin-top: 30px;
  position: relative;
  width: 100%;

  .open-app-btn {
    margin-top: 2rem;
    border-radius: 30px;
    background: #efefef;
    margin-bottom: 5px;

    div > div {
      color: #006dfe;
    }

    &:active {
      background: linear-gradient(143.11deg, #006dfe 21.44%, #43e8e1 126.28%);

      div > div {
        color: #efefef;
      }
    }
  }

  .btn-connect {
    width: 240px;
    height: auto;
    background: unset;
    border: unset;
    position: relative;
    cursor: pointer;

    & > div {
      color: #000;
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: 0.04em;

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .btn-swap {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;
    max-width: 130px;
    background: unset;
    border: unset;
    cursor: pointer;
  }

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
  }
`

interface Props {
  visible: boolean
  links: MenuEntryTypes[]
  accountEllipsis?: string
  toggleWallet: () => void
  toggleMenu: () => void
  showSlider: boolean
}

const MobileSlider = ({ visible, links, accountEllipsis, toggleWallet, toggleMenu, showSlider }: Props) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { toastSuccess, toastError } = useToast()

  const handleComingSoon = () => {
    toastSuccess('Coming Soon')
  }

  return (
    <>
      <Wrapper visible={visible}>
        <CollapseIcon
          active={showSlider}
          toggleMenu={toggleMenu}
          styles={{ position: 'absolute', top: '40px', right: '35px' }}
        />

        <ul>
          <LocalizeSelector />

          {links.map((entry: any) => {
            const paths = router.pathname.split('/').map((path) => `/${path}`)
            if (entry.items) {
              return (

                <SubListItem label={entry.label} subMenu={entry.items} toggleMenu={toggleMenu} />
              )
            }
            return (

              entry.isComing ?


                <Box
                  key={`menu-${entry.label}`}
                  className={paths.includes(entry.href || '') ? 'active' : ''}
                  onClick={handleComingSoon}
                >

                  <div className='no-link'>

                    <MenuLink>{t(entry.label)}</MenuLink>

                  </div>

                </Box>

                :
                <MenuEntry
                  key={`menu-${entry.label}`}
                  className={paths.includes(entry.href || '') ? 'active' : ''}
                  onClick={toggleMenu}
                >
                  <MenuLink href={entry.href}>{t(entry.label)}</MenuLink>
                </MenuEntry>
            )
          })}
        </ul>

        <div className="social-network">
          <a className="comming-soon tele-group" href="https://t.me/asdinft_english" target="_blank" rel="noreferrer">
            <img src="/images/social-network/v2/telegram.svg" alt="" />
          </a>
          <a
            className="comming-soon tele-channel"
            href="https://t.me/asdinft_announcements"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/social-network/v2/telegram-gl.svg" alt="" />
          </a>
          <a href="https://twitter.com/asdinft" target="_blank" rel="noreferrer">
            <img src="/images/social-network/v2/twitter.svg" alt="" />
          </a>
          <a href="https://www.linkedin.com/company/asdinft" target="_blank" rel="noreferrer">
            <img src="/images/social-network/v2/linkedin.svg" alt="" />
          </a>
          <a href="https://www.linkedin.com/company/asdinft" target="_blank" rel="noreferrer">
            <img src="/images/social-network/v2/facebook.svg" alt="" />
          </a>
          <a href="https://discord.com/invite/Cn6CKcUYYp" target="_blank" rel="noreferrer">
            <img src="/images/social-network/v2/discord.svg" alt="" />
          </a>
          <a href="https://www.youtube.com/@asdinft" target="_blank" rel="noreferrer">
            <img src="/images/social-network/v2/youtube.svg" alt="" />
          </a>
          <a href="https://www.instagram.com/asdinft" target="_blank" rel="noreferrer">
            <img src="/images/social-network/v2/instagram.svg" alt="" />
          </a>
          <a href="/" target="_blank" rel="noreferrer">
            <img src="/images/social-network/v2/medium.svg" alt="" />
          </a>
        </div>
      </Wrapper>
    </>
  )
}
export default MobileSlider
