import { Button, useWalletModal, ButtonProps } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import Trans from './Trans'

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  return (
    // disable function
    // <Button onClick={onPresentConnectModal} {...props}>
    //   {children || <Trans>Connect Wallet</Trans>}
    // </Button>

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Button onClick={() => { }} {...props}>
      {children || <Trans>Connect Wallet</Trans>}
    </Button>
  )
}

export default ConnectWalletButton
