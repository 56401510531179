import { MenuEntry } from './types'

/**
 * label: string
 * icon: svg
 * href: https || /path || 'function'
 * calloutClass: 'rainbow' || className => background rainbow and custom css
 * initialOpenState: true || false
 * items: array sample parent
 * att: attach => text || icon.png
 */

export const menuItems = [
  {
    label: 'ASDI token',
    icon: 'home.svg',
    href: '/home',
    actives: ['/home'],
    isComing: false
  },
  {
    label: 'ASDIR ',
    icon: 'document.svg',
    href: '#',
    actives: [''],
    isComing: true
  },
  // {
  //   label: 'Buy',
  //   icon: 'document.svg',
  //   href: '/buy',
  //   actives: ['/buy'],
  //   isNoTarget: true,
  //   isComing: false
  // },
  {
    label: 'Audit',
    icon: 'history.svg',
    href: '#',
    actives: [''],
    isComing: true
  },
  {
    label: 'Governance',
    icon: 'history.svg',
    href: '#',
    actives: [''],
    isComing: true
  },
  {
    label: 'Staking',
    icon: 'history.svg',
    href: '#',
    actives: [''],
    isComing: true
  },
  {
    label: 'Airdrop',
    icon: 'roadmap.svg',
    href: '#',
    actives: [''],
    isComing: true
  },
  {
    label: 'ASDI Marketplace',
    icon: 'history.svg',
    href: 'https://testnet.asdi.market',
    actives: ['https://testnet.asdi.market'],
    isComing: false
  },
  {
    label: ' ASDI Earn',
    icon: 'history.svg',
    href: 'https://testnet.asdiearn.io/home',
    actives: ['https://testnet.asdiearn.io/home'],
    isComing: false
  },
  {
    label: 'Community',
    icon: 'history.svg',
    href: '#',
    actives: [''],
    isComing: false,
    items: [
      {
        label: 'Telegram Channel',
        icon: '/images/social-network/telegram.svg',
        iconLight: 'copy-2-light.svg',
        href: 'https://t.me/asdinft_announcements',
        actives: ['/collection'],
      },
      {
        label: 'Telegram Group',
        icon: '/images/social-network/telegram.svg',
        iconLight: 'create-nft-light.svg',
        href: 'https://t.me/asdinft_english',
        actives: ['/nft'],
      },
      {
        label: 'Twitter',
        icon: '/images/social-network/twitter.svg',
        iconLight: 'create-nft-light.svg',
        href: 'https://twitter.com/asdinft',
        actives: ['/nft'],
      },
      {
        label: 'LinkedIn',
        icon: '/images/social-network/linkedin.png',
        iconLight: 'create-nft-light.svg',
        href: 'https://www.linkedin.com/company/asdinft',
        actives: ['/nft'],
      }, {
        label: 'Facebook',
        icon: '/images/social-network/facebook.png',
        iconLight: 'create-nft-light.svg',
        href: 'https://www.facebook.com/asdinft',
        actives: ['/nft'],
      }, {
        label: 'Discord',
        icon: '/images/social-network/discord.png',
        iconLight: 'create-nft-light.svg',
        href: 'https://discord.com/invite/Cn6CKcUYYp',
        actives: ['/nft'],
      }, {
        label: 'Youtube',
        icon: '/images/social-network/youtube.png',
        iconLight: 'create-nft-light.svg',
        href: 'https://www.youtube.com/@asdinft',
        actives: ['/nft'],
      },
      {
        label: 'Instagram',
        icon: '/images/social-network/instagram.png',
        iconLight: 'create-nft-light.svg',
        href: 'https://www.instagram.com/asdinft',
        actives: ['/nft'],
      },
      {
        label: 'Medium',
        icon: '/images/social-network/ss.png',
        iconLight: 'create-nft-light.svg',
        href: 'https://medium.com/@asdinft',
        actives: ['/nft'],
      },
    ],
  },
]

export default menuItems
